

.timeline-icon__container {
  position: relative;
  display: inline-flex;
  color: #fff;
  font-size: 2rem;
  align-items: center;
  min-width: 68px !important;
  min-height: 68px !important;
  justify-content: center;
  background-color: #ed6c02;
  border-radius: 50%;
  padding: 0 10px;
  overflow: hidden;

  .timeline-icon {
    // display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .timeline-icon__text {
    color: #ffffff;
    position: absolute; 
    bottom: -7px;
    font-size: 24px;
    font-weight: bold;
  }
}

#about [class$="-MuiTypography-root-MuiTimelineOppositeContent-root"] {
  visibility: hidden;
  // margin: 0;
  // padding: 40px 16px;
  // background: var(--hs-white)
}
// #about [class$="-MuiTypography-root-MuiTimelineContent-root"] {
//   .hs-timeline__date {
//     left: 40%;
//     right: auto
//   }
// }


#about .MuiTypography-root {
  color: #fff;
}

#about .MuiTypography-root.MuiTypography-h4 {
  color: #333;
}

#about .hs-timeline__content-footer {
  color: #333;
  text-align: center;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
}

.MuiTimelineDot-root {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 100;
  
  transform: translateX(-50%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  &:nth-child(even) {
    flex-direction: row-reverse; // for even blocks -> lay out content from right to left
  }

  &::before {
    position: absolute;
    top: 40%;
    left: -18px;
    z-index: 99;
    height: 0; 
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right: 20px solid #ed6c02; 
  }
  
  &::after {
    position: absolute;
    top: 40%;
    right: -18px;
    z-index: 99;
    height: 0; 
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-left: 20px solid #ed6c02; 
  }
}

.MuiTimelineSeparator-root{
  &[class$="-MuiTimelineSeparator-root"] {
    margin: 0 25px;
  }
}

[class$="-MuiTimelineConnector-root"] {
  width: 2px;
  background-color: #bdbdbd;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
}

#about .hs-timeline__date {
  color: #333;
  margin: auto;
}


// .MuiTypography-root-MuiTimelineContent-root {
  // position: absolute;
  // left: 100%;
  // right: auto;
  // transform: translateX(50px)

  // .hs-timeline__block {

  // }
// }

// .MuiTimelineContent-root {

// }
