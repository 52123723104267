@import '../../scss/bootstrap/scss/mixins/breakpoints';
@import '../../scss/variables/maps';
@import '../../scss/variables/colors';
@import '../../scss/variables/mixins';
@import '../../scss/effects/glass.scss';

.hsSection {
  width: 100%;
  // padding: 6rem 0;

  .hsSectionHeading {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 4em;
    @include heading-font;
  }

  .hsSectionSubheading {
    margin-bottom: .5em;
    @include cursive-font;
    font-size: 3.5em;
    font-style: italic;
    font-weight: 400;
  }
  .hsSectionSubheadingSmall {
    margin-bottom: .5em;
    @include cursive-font;
    font-size: 2.25em;
    font-style: italic;
    font-weight: 400;
  }
}

@include media-breakpoint-up(md) {
  .hsSection {
    padding: 6em 0;
  }
}





/* Services Section */
.services, .hsSkills {
  color: #232323;
  background-image: url('/assets/img/bg/gray-internal-structure-building.jpg');
   background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  background-blend-mode: lighten;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-top: 80px;
  text-align: center;
  overflow-x: hidden;
  box-sizing: border-box;
  box-shadow: inset 0px 8px 20px 2px #232323;
  &::before {
    position: absolute;
    z-index: 1000;
    background: #fff;
  }
  .giantText {
    font-size: 5rem;
    font-weight: bold;
    font-style: none;
    align-self: center;
  }
  .hsSectionHeading {
    text-align: center;
    margin-top: 0;
    margin-bottom: .5rem;
    font-size: 4em;
    color: #ffffff;
    @include heading-font;
    text-shadow: 0px 6px 20px rgb(0 0 0 / 91%);
  }
  .hsSectionSubheading {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5em;
    margin-top: 1rem;
    @include cursive-font;
    font-size: 3.5em;
    // font-style: italic;
    text-stroke-width: 2px;
    text-stroke-color: $hs-white;
    color: $hs-white;
    transform: rotate(355deg);
    -webkit-text-stroke-color: var(--primary);
    -webkit-text-stroke-width: 2px;
    -webkit-text-fill-color: currentColor;
    text-shadow: 2px 6px 7px rgb(0 0 0 / 46%);
  }
  .hsSectionSubheadingSmall {
    margin-bottom: .5em;
    @include heading-font;
    font-size: 2em;
    font-weight: 700;
    color: var(--hs-white);
  }
  .hsCard {
    background:  #232323;
  }
  .bgBlack_75, .bgBlack_75 {
    background: var(--hs-black_75)
  }
  .bg-black_50, .bgBlack_50 {
    background: var(--hs-black_50)
  }
  .bgBlack {
    background: var(--bs-dark)
  }
  .bgDark {
    background: rgba(40, 44, 52, 1);
  }
}


// .blurredGlassBg {
//   background: rgba(255, 255, 255, 0.09);
//   border-radius: 16px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(5px);
//   border: 1px solid rgba(255, 255, 255, 0.3);
//   border-bottom: none;
// }
// .blurredGlassBgLeft {
//   background: rgba(255, 255, 255, 0.09);
//   border-radius: 16px;
//   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(5px);
//   border: 1px solid rgba(255, 255, 255, 0.3);
//   border-bottom: none;
//   border-top-left-radius: 16px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   // @include border-top-radius($border-radius-lg);
// }

// .blurredGlassBgRight {
//   background: rgba(255, 255, 255, 0.09);
//   border-radius: 16px;
//   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(5px);
//   border: 1px solid rgba(255, 255, 255, 0.3);
//   border-bottom: none;
//   border-top-left-radius: 0;
//   border-top-right-radius: 16px;
//   border-bottom-left-radius: 0;
//   // @include border-top-radius($border-radius-lg);
// }
