$hs-ns: "hs";
@import "./maps";

// Gray and Brand Colors for use across theme
$hs-black:      #000 !default;
$hs-black--a15: rgba(0, 0, 0, 0.15) !default;
$hs-black--a25: rgba(0, 0, 0, 0.25) !default;
$hs-black--a45: rgba(0, 0, 0, 0.45) !default;
$hs-black--a50: rgba(0, 0, 0, 0.5) !default;
$hs-black--a75: rgba(0, 0, 0, 0.75) !default;


$hs-white--a08: rgba(255, 255, 255, 0.08) !default;
$hs-white--a10: rgba(255, 255, 255, 0.1) !default;
$hs-white--a30: rgba(255, 255, 255, 0.3) !default;
$hs-white--a50: rgba(255, 255, 255, 0.5) !default;
$hs-white--a60: rgba(255, 255, 255, 0.6) !default;
$hs-white--a75: rgba(255, 255, 255, 0.75) !default;
$hs-white--a90: rgba(255, 255, 255, 0.9) !default;

$hs-white:      #fff !default;
$hs-gray-50:    #f2f2f2 !default;
$hs-gray-100:   #f8f8f8 !default;
$hs-gray-200:   #ebebeb !default;
$hs-gray-300:   #dee2e6 !default;
$hs-gray-400:   #ced4da !default;
$hs-gray-500:   #adb5bd !default;
$hs-gray-600:   #6c757d !default;
$hs-gray-700:   #495057 !default;
$hs-gray-800:   #343a40 !default;
$hs-gray-900:   #212529 !default;
$hs-gray-950:   #151515 !default;
$hs-black:      #000 !default;
$hs-gray:         $hs-gray-500 !default;

// // stylelint-disable-next-line scss/dollar-variable-default
$hs-grays: (
    "0": $hs-white,
    "100": $hs-gray-100,
    "200": $hs-gray-200,
    "300": $hs-gray-300,
    "400": $hs-gray-400,
    "500": $hs-gray-500,
    "600": $hs-gray-600,
    "700": $hs-gray-700,
    "800": $hs-gray-800,
    "900": $hs-gray-900,
    "950": $hs-gray-950,
    "1000": $hs-black,
) !default;

// // Override Bootstrap color system
// $yellow:     #f2b01e !default;

// // Override Bootstrap default state color
// $hs-prime-yellow:   $yellow !default;
// $hs-prime-yellow--a50: rgba(254, 211, 54, 0.486) !default;
// $primary:      $blue !default;

// // Tint
// $hs-darken-tint: 15% !default;@import "./variables/maps";
// $yiq-contrasted-threshold:    205 !default;

// fusv-enable
$hs-blue:    #0051bb !default;
$hs-indigo:  #766cff !default;
$hs-purple:  #5d0181 !default;
$hs-pink:    #e83e8c !default;
$hs-red:     #cf162b !default;
$hs-orange:  #ff7505 !default;
$hs-yellow:  #fed136 !default;
$hs-green:   #008d4b !default;
$hs-teal:    #20c9bb !default;
$hs-cyan:    #05a0b8 !default;

// scss-docs-start colors-map
$hs-colors: (
  "blue":       $hs-blue,
  "indigo":     $hs-indigo,
  "purple":     $hs-purple,
  "pink":       $hs-pink,
  "red":        $hs-red,
  "orange":     $hs-orange,
  "yellow":     $hs-yellow,
  "green":      $hs-green,
  "teal":       $hs-teal,
  "cyan":       $hs-cyan,
  "white":      $hs-white,
  "gray":       $hs-gray-600,
  "gray-dark":  $hs-gray-800
) !default;
// scss-docs-end colors-map

// Override Bootstrap color system
$yellow:          #f2b01e !default;
$hs-yellow:       #ffc107 !default;
$hs-yellow-hover: #ffe659 !default;
$hs-light-yellow: #ffe659 !default;

$hs-prime-yellow:  $yellow !default;
$hs-secondary:     $hs-gray-600 !default;
$hs-success:       $hs-green !default;
$hs-info:          $hs-cyan !default;
$hs-warning:       $hs-yellow !default;
$hs-danger:        $hs-red !default;
$hs-light:         $hs-gray-100 !default;
$hs-dark:          $hs-gray-900 !default;

$interaction-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$interaction-colors:(

    "primary": $hs-prime-yellow,
    "secondary": $hs-secondary,
    "success": $hs-success,
    "info": $hs-info,
    "warning": $hs-warning,
    "danger": $hs-danger,
    "light": $hs-gray-100,
    "white": $hs-white,
    "dark": $hs-gray-600,

) !default;


// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$hs-min-contrast-ratio:       4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$hs-contrast-dark:      $hs-black !default;
$hs-contrast-light:     $hs-white !default;

// fusv-disable
$hs-blue-100: tint-color($hs-blue, 80%) !default;
$hs-blue-200: tint-color($hs-blue, 60%) !default;
$hs-blue-300: tint-color($hs-blue, 40%) !default;
$hs-blue-400: tint-color($hs-blue, 20%) !default;
$hs-blue-500: $hs-blue !default;
$hs-blue-600: shade-color($hs-blue, 20%) !default;
$hs-blue-700: shade-color($hs-blue, 40%) !default;
$hs-blue-800: shade-color($hs-blue, 60%) !default;
$hs-blue-900: shade-color($hs-blue, 80%) !default;

$hs-indigo-100: tint-color($hs-indigo, 80%) !default;
$hs-indigo-200: tint-color($hs-indigo, 60%) !default;
$hs-indigo-300: tint-color($hs-indigo, 40%) !default;
$hs-indigo-400: tint-color($hs-indigo, 20%) !default;
$hs-indigo-500: $hs-indigo !default;
$hs-indigo-600: shade-color($hs-indigo, 20%) !default;
$hs-indigo-700: shade-color($hs-indigo, 40%) !default;
$hs-indigo-800: shade-color($hs-indigo, 60%) !default;
$hs-indigo-900: shade-color($hs-indigo, 80%) !default;

$hs-purple-100: tint-color($hs-purple, 80%) !default;
$hs-purple-200: tint-color($hs-purple, 60%) !default;
$hs-purple-300: tint-color($hs-purple, 40%) !default;
$hs-purple-400: tint-color($hs-purple, 20%) !default;
$hs-purple-500: $hs-purple !default;
$hs-purple-600: shade-color($hs-purple, 20%) !default;
$hs-purple-700: shade-color($hs-purple, 40%) !default;
$hs-purple-800: shade-color($hs-purple, 60%) !default;
$hs-purple-900: shade-color($hs-purple, 80%) !default;

$hs-pink-100: tint-color($hs-pink, 80%) !default;
$hs-pink-200: tint-color($hs-pink, 60%) !default;
$hs-pink-300: tint-color($hs-pink, 40%) !default;
$hs-pink-400: tint-color($hs-pink, 20%) !default;
$hs-pink-500: $hs-pink !default;
$hs-pink-600: shade-color($hs-pink, 20%) !default;
$hs-pink-700: shade-color($hs-pink, 40%) !default;
$hs-pink-800: shade-color($hs-pink, 60%) !default;
$hs-pink-900: shade-color($hs-pink, 80%) !default;

$hs-red-100: tint-color($hs-red, 80%) !default;
$hs-red-200: tint-color($hs-red, 60%) !default;
$hs-red-300: tint-color($hs-red, 40%) !default;
$hs-red-400: tint-color($hs-red, 20%) !default;
$hs-red-500: $hs-red !default;
$hs-red-600: shade-color($hs-red, 20%) !default;
$hs-red-700: shade-color($hs-red, 40%) !default;
$hs-red-800: shade-color($hs-red, 60%) !default;
$hs-red-900: shade-color($hs-red, 80%) !default;

$hs-orange-100: tint-color($hs-orange, 80%) !default;
$hs-orange-200: tint-color($hs-orange, 60%) !default;
$hs-orange-300: tint-color($hs-orange, 40%) !default;
$hs-orange-400: tint-color($hs-orange, 20%) !default;
$hs-orange-500: $hs-orange !default;
$hs-orange-600: shade-color($hs-orange, 20%) !default;
$hs-orange-700: shade-color($hs-orange, 40%) !default;
$hs-orange-800: shade-color($hs-orange, 60%) !default;
$hs-orange-900: shade-color($hs-orange, 80%) !default;

$hs-yellow-100: tint-color($hs-yellow, 80%) !default;
$hs-yellow-200: tint-color($hs-yellow, 60%) !default;
$hs-yellow-300: tint-color($hs-yellow, 40%) !default;
$hs-yellow-400: tint-color($hs-yellow, 20%) !default;
$hs-yellow-500: $hs-yellow !default;
$hs-yellow-600: shade-color($hs-yellow, 20%) !default;
$hs-yellow-700: shade-color($hs-yellow, 40%) !default;
$hs-yellow-800: shade-color($hs-yellow, 60%) !default;
$hs-yellow-900: shade-color($hs-yellow, 80%) !default;

$hs-green-100: tint-color($hs-green, 80%) !default;
$hs-green-200: tint-color($hs-green, 60%) !default;
$hs-green-300: tint-color($hs-green, 40%) !default;
$hs-green-400: tint-color($hs-green, 20%) !default;
$hs-green-500: $hs-green !default;
$hs-green-600: shade-color($hs-green, 20%) !default;
$hs-green-700: shade-color($hs-green, 40%) !default;
$hs-green-800: shade-color($hs-green, 60%) !default;
$hs-green-900: shade-color($hs-green, 80%) !default;

$hs-teal-100: tint-color($hs-teal, 80%) !default;
$hs-teal-200: tint-color($hs-teal, 60%) !default;
$hs-teal-300: tint-color($hs-teal, 40%) !default;
$hs-teal-400: tint-color($hs-teal, 20%) !default;
$hs-teal-500: $hs-teal !default;
$hs-teal-600: shade-color($hs-teal, 20%) !default;
$hs-teal-700: shade-color($hs-teal, 40%) !default;
$hs-teal-800: shade-color($hs-teal, 60%) !default;
$hs-teal-900: shade-color($hs-teal, 80%) !default;

$hs-cyan-100: tint-color($hs-cyan, 80%) !default;
$hs-cyan-200: tint-color($hs-cyan, 60%) !default;
$hs-cyan-300: tint-color($hs-cyan, 40%) !default;
$hs-cyan-400: tint-color($hs-cyan, 20%) !default;
$hs-cyan-500: $hs-cyan !default;
$hs-cyan-600: shade-color($hs-cyan, 20%) !default;
$hs-cyan-700: shade-color($hs-cyan, 40%) !default;
$hs-cyan-800: shade-color($hs-cyan, 60%) !default;
$hs-cyan-900: shade-color($hs-cyan, 80%) !default;
// fusv-enable

// background colors
$hs-background-primary: $hs-gray-50 !default;
$hs-background-secondary: $hs-gray-200 !default;


// Random Color Varient Variables
$hs-red-orange: #f63a0f !default;
$hs-yellow--light: #ffe659 !default;
$hs-yellow--dark: #e2b900 !default;
$hs-yellow--darker: #f2b01e !default;
$hs-lime--light: #e7ffcb !default;
$hs-lime: #86e01e !default;
$hs-green--light: #93ff7e !default;
$hs-green--dark: #468c1f !default;
$hs-green--darker: #28610f !default;
$hs-silver: #aaa !default;
$hs-turquoise: #5ac8fa !default;
$hs-blue-semimuted: #4997cd !default;
$hs-hsl-grey: hsl(207, 10%, 55%);
$hs-hsl-grey-light: hsl(206, 13%, 79%);
$hs-hsl-green: hsl(111, 51%, 60%);
$hs-hsl-red: hsl(353, 76%, 50%);
$hs-hsl-yellow: hsl(47, 85%, 61%);
$hs-hsl-white: hsl(0, 0%, 100%);

// Dark Mode Colors
$hs-blueGray-medium: #507386 !default;
$hs-blueGray-medium-muted: #476e82 !default;
$hs-blueGray-medium-saturated: #136591 !default;
$hs-blueGray-dark: #1e2e3e !default;
$hs-blueGray-very-dark: #181a1b !default;
$hs-blueGray-darker: #0c0f11 !default;
$hs-blueGray-darker--alpha75: rgba(12, 15, 17, 0.75) !default;
$hs-blueGray-light: #2d373c !default;
$hs-text-onDark: map-get($hs-dark-mode-colors, text-onDark) !default;
$hs-green-light: #80cc94 !default;
$hs-blue-light: #0019d3 !default;
// $hs-blue--lighter:               #83d6ff !default;
// $hs-blue--lighter:               #83d6ff !default;
$hs-blue--lighter: #78bad8 !default;
$hs-blue--lightest: #a8dfff !default;
$hs-blue--light-muted: #80b6cc !default;
$hs-blue--lighter-muted: #8ec1d9 !default;
$hs-blue--lightest-muted: #9bcbe6 !default;
$hs-blue--light-shadow: #3ab1c3 !default;
$hs-blue--light-background: #117a8b !default;
$hs-blue--dark: #1e364e !default;
$hs-link--primary: $hs-prime-yellow !default;
$hs-clip-background: content-box !default;

$hs-baige-light:             #cac1ac !default;
$hs-baige-medium:            #a79e8b !default;


// Override Bootstrap color system
$yellow:                      #f2b01e !default;

// Override Bootstrap default state color
$hs-prime-yellow:               $yellow !default;
$hs-prime-yellow--a50:        rgba(254, 211, 54, 0.486) !default;
$hs-primary:                    $yellow !default;

// Tint
$hs-darken-tint:                15% !default;
$hs-lighten-tint:               15% !default;
$hs-lightnessMultiplier:        0.9 !default;

// Override Bootstrap yiq lightness value
$hs-yiq-contrasted-threshold:   205 !default;
$yiq-contrasted-threshold:      205 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$hs-enable-caret:                true !default;
$hs-enable-rounded:              true !default;
$hs-enable-shadows:              false !default;
$hs-enable-gradients:            false !default;
$hs-enable-transitions:          true !default;
$hs-enable-reduced-motion:       true !default;
$hs-enable-smooth-scroll:        true !default;
$hs-enable-grid-classes:         true !default;
$hs-enable-button-pointers:      true !default;
$hs-enable-rfs:                  true !default;
$hs-enable-validation-icons:     true !default;
$hs-enable-negative-margins:     false !default;
$hs-enable-deprecation-messages: true !default;
$hs-enable-important-utilities:  true !default;

// Prefix for :root CSS variables

$-variable-prefix:             hs- !default;

// Gradient
//
// The gradient which is added to components if `$hs-enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$hs-gradient: linear-gradient(180deg, rgba($hs-white, .15), rgba($hs-white, 0)) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.


// Body
//
// Settings for the `<body>` element.

$hs-body-bg:                   $hs-white !default;
$hs-body-color:                $hs-gray-900 !default;
$hs-body-text-align:           null !default;


// Links
//
// Style anchor elements.

$hs-link-color:                              $hs-yellow--dark !default;
$hs-link-decoration:                         underline !default;
$hs-link-shade-percentage:                   20% !default;
$hs-link-hover-color:                        shift-color($hs-link-color, $hs-link-shade-percentage) !default;
$hs-link-hover-decoration:                   null !default;

$hs-stretched-link-pseudo-element:           after !default;
$hs-stretched-link-z-index:                  1 !default;

$hs-color-text: $hs-gray-700 !default;
$hs-color-text-onDark: $hs-white !default;

$font-family-base: 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', -apple-system, BlinkMacSystemFont,  Arial, sans-serif;
$headings-font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', Arial, sans-serif, -apple-system, BlinkMacSystemFont;
$subheadings-font-family: 'Roboto Slab', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', Arial, sans-serif, -apple-system, BlinkMacSystemFont;
$font-family-script: Caveat, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', Arial, sans-serif, -apple-system, BlinkMacSystemFont;
$alt-headings-font-family: 'Bebas Neue',sans-serif;
$headings-font-weight: 700 !default;
$headings-letter-spacing: 0.0625em !default;

$hs-font-primary: $font-family-base !default;
$hs-font-secondary: $headings-font-family !default;
$hs-font-alt-headings: $alt-headings-font-family !default;
$hs-font-serif: $subheadings-font-family !default;
$hs-font-cursive-primary: $font-family-script !default;
$hs-font-cursive-secondary: "Nothing You Could Do", cursive !default;
$hs-font-cursive-Sedgwick: "Sedgwick Ave Display", cursive !default;
$hs-font-size-root: 1rem !default;
$hs-font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$hs-font-size-lg: $hs-font-size-base * 1.25 !default;
$hs-font-size-sm: $hs-font-size-base * 0.875 !default;

$hs-font-weight-lighter: lighter !default;
$hs-font-weight-light: 300 !default;
$hs-font-weight-normal: 400 !default;
$hs-font-weight-bold: 700 !default;
$hs-font-weight-bolder: bolder !default;

$hs-font-weight-base: $hs-font-weight-normal !default;
$hs-line-height-base: 1.5 !default;

$hs-h1-font-size: $hs-font-size-base * 2.5 !default;
$hs-h2-font-size: $hs-font-size-base * 2 !default;
$hs-h3-font-size: $hs-font-size-base * 1.75 !default;
$hs-h4-font-size: $hs-font-size-base * 1.5 !default;
$hs-h5-font-size: $hs-font-size-base * 1.25 !default;
$hs-h6-font-size: $hs-font-size-base !default;

// Paddding & Margins Generics
$hs-spacing--xs: 0.25em !default;
$hs-spacing--sm: 0.5em !default;
$hs-spacing--md: 1em !default;
$hs-spacing--lg: 1.5em !default;

$hs-headings-margin-bottom: $hs-spacing--md * 0.5 !default;
$hs-headings-font-family: null !default;
$hs-headings-font-weight: 500 !default;
$hs-headings-line-height: 1.2 !default;
$hs-headings-color: null !default;

$hs-display1-size: 6rem !default;
$hs-display2-size: 5.5rem !default;
$hs-display3-size: 4.5rem !default;
$hs-display4-size: 3.5rem !default;

$hs-display1-weight: 300 !default;
$hs-display2-weight: 300 !default;
$hs-display3-weight: 300 !default;
$hs-display4-weight: 300 !default;
$hs-display-line-height: $hs-headings-line-height !default;

$hs-lead-font-size: $hs-font-size-base * 1.25 !default;
$hs-lead-font-weight: 300 !default;

$hs-small-font-size: 80% !default;

$hs-text-muted: $hs-white--a60 !default;

$hs-text-shadow-light: 0px 0px 4px rgba(0, 0, 0, 0.95) !default;
$hs-text-shadow-light-spread: 0px 4px 13px rgba(0, 0, 0, 0.75) !default;
$hs-text-shadow-medium: 0px 0px 10px rgba(0, 0, 0, 0.85) !default;

$hs-canvas: $hs-gray-100 !default;
$hs-surface: $hs-white !default;

$hs-grid-columns: 12 !default;
$hs-grid-gutter-width: 30px !default;
$hs-grid-row-columns: 6 !default;

// Borders
$hs-border-width: 1px !default;
$hs-border-color: $hs-gray-300 !default;

$hs-enable-rounded: true !default;

$hs-border-radius: 0.25rem !default; // 4px
$hs-border-radius-sm: 0.125rem !default; // 2px
$hs-border-radius-lg: 0.5rem !default; //8px
$hs-border-radius-md: 0.25% !default;

// Outer glow
$hs-box-shadow-glow: 0px 4px 16px 2px rgba(255, 255, 255, 0.5) !default;

// Shadows thank you https://html-css-js.com/css/generator/box-shadow/
$hs-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-md: 0px 3px 8px 2px rgba(0, 0, 0, 0.2) !default;
$hs-shadow-heavy: 2px 0.5rem 0.5rem rgba(0, 0, 0, 0.5) !default;
$hs-shadow-xs: 2px 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-xs-reverse: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-xs-center: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-xs-center-xtra: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15), 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-xs-center-reverse: -2px 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !default;
$hs-shadow-sm: 0px 3px 3px 0px rgba(0, 0, 0, 0.25) !default;
$hs-shadow-lg: 0px 4px 8px 3px rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) !default;
$hs-shadow-inset-blue: inset 0 0 0 2px #5173ff !default;
$hs-shadow-none: none !default;
$hs-card-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.45), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !default;
$hs-card-shadow-2: 0 4px 6px 2px rgba(0, 0, 0, 0.3) !default;
$hs-button-shadow-nocolor: 0px 1px 5px 1px !default;
$hs-button-focus: $hs-button-shadow-nocolor $hs-prime-yellow--a50 !default;
$hs-button-secondary-focus: $hs-button-shadow-nocolor $hs-gray-600 !default;
$hs-button-success-shadow: $hs-button-shadow-nocolor rgba(darken($hs-green--darker, 5%), 45%) !default;
$hs-button-info-shadow: $hs-button-shadow-nocolor rgba(darken($hs-blue--light-shadow, 5%), 45%) !default;
$hs-button-warning-shadow: $hs-button-shadow-nocolor rgba(darken($hs-orange, 5%), 45%) !default;
$hs-button-danger-shadow: $hs-button-shadow-nocolor rgba(darken($hs-red, 5%), 45%) !default;
// Backgrounds
$hs-bg-black-to-transparent: linear-gradient(0deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 1) 100%) !default;

// Cards
// for responsive only
$hs-card-width: 100% !default;
$hs-card-height: 350px !default;
$hs-card-title: 200% !default;
$hs-card-subtitle: calc($hs-card-title - 10%) !default;
$hs-card-img-width: $hs-card-width !default;
$hs-card-img-height: auto !default;

// for breakpoint sm
$hs-card-width--sm: 265px !default;
$hs-card-title--sm: 1.1rem !default;
$hs-card-subtitle--sm: calc($hs-card-title--sm - 10%) !default;
$hs-card-img-width--sm: 100% !default;
$hs-card-img-height--sm: auto !default;

// for breakpoint lg
$hs-card-width--lg: 400px !default;
$hs-card-title--lg: 2.3rem !default;
$hs-card-subtitle--lg: calc($hs-card-title--lg - 10%) !default;
$hs-card-img-width--lg: $hs-card-width--lg !default;
$hs-card-img-height--lg: auto !default;

$hs-card-margin: 0.5rem !default;

// Card padding - borders - margins
$hs-card-spacer-y: 0.75rem !default;
$hs-card-spacer-x: 1.25rem !default;
$hs-card-border-width: $hs-border-width !default;
$hs-card-border-radius: $hs-border-radius !default;
$hs-card-border-color: rgba($hs-black, 0.125) !default;
$hs-card-inner-border-radius: $hs-border-radius-sm !default;
$hs-card-cap-bg: rgba($hs-black, 0.03) !default;
$hs-card-cap-color: null !default;
$hs-card-color: null !default;
$hs-card-bg: $hs-gray-100 !default;
$hs-card-bg--dark: $hs-blueGray-dark !default;
$hs-card-header-bg: $hs-white !default;
$hs-card-body-bg: $hs-gray-400 !default;
$hs-card-content-bg: $hs-white !default;
$hs-card-footer-bg: $hs-white !default;

$hs-card-img-overlay-padding: 1.25rem !default;

$hs-card-group-margin: $hs-grid-gutter-width * 0.5 !default;
$hs-card-deck-margin: $hs-card-group-margin !default;

$hs-card-columns-count: 3 !default;
$hs-card-columns-gap: 1.25rem !default;
$hs-card-columns-margin: $hs-card-spacer-y !default;

// ====== Z-INDEX ====== //
$hs-zindex-dropdown: 1000 !default;
$hs-zindex-sticky: 1020 !default;
$hs-zindex-fixed: 1030 !default;
$hs-zindex-modal-backdrop: 1040 !default;
$hs-zindex-modal: 1050 !default;
$hs-zindex-popover: 1060 !default;
$hs-zindex-tooltip: 1070 !default;

// ====== SHAPES ====== //
$hs-circle: 50% !default;
$hs-pill: 500% !default;

// ===== =RATIO ====== //
$hs-ratio-16-9: 56.25% !default; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
$hs-ratio-4-3: 75% !default; // 4:3 Aspect Ratio (divide 3 by 4 = 0.75)
$hs-ratio-3-2: 66.66% !default;
$hs-ratio-1-1: 100% !default; // 1:1 Aspect Ratio
