.HSRater {
  background: transparent !important;
}

span[class$="-MuiRating-root"] {
  display: inline-flex;
  margin: .375rem auto;
  justify-content:center;
  // transform: translateX(50%)
}

#skills .skills-list .MuiBox-root {
  margin: auto;
  text-align: center;
}

.MuiBox-root:has(.MuiRating-root) {
  margin: auto;
  text-align: center;
}

.MuiBox-root:has(.rater-image) {
  margin: auto;
  text-align: center;
}

// #skills .skills-list .MuiRating-root .MuiRating-sizeMedium .Mui-readOnly .MuiRating-readOnly css-ryrseu-MuiRating-root
