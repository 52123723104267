@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/mixins/border-radius";

@import "./maps";
@import "./functions";
@import "./colors";
@import "./breakpoints";

// @import "../variables/typography";
// @import "../variables/spacing";
// @import "../variables/grid";
// @import "../variables/keyframes";
// @import "../variables/aspect-ratio";

// Mixins

@mixin hs-background-clip($hs-clip-background) {
  background-clip: $hs-clip-background;
}

@mixin hs-clip-text($img-url, $fallback-color: #000) {
  color: $fallback-color;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(transparent, transparent), url($img-url) repeat;
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
}

// @mixin button {
//   @include disabled;
//   display: inline-block;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: $hs-blue--dark;
//   text-align: center;
//   text-decoration: none;
//   text-overflow: ellipsis;
//   vertical-align: middle;
//   user-select: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   border-radius: 0;
//   appearance: none;
//   outline: 0;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

@mixin button {
  @include hs-button-color;
  @include disabled;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $hs-blue--dark;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  appearance: none;
  outline: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@mixin button-nude {
  @include disabled;
  width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  background: inherit;
  border: 0;
  border-radius: 0;
  outline: 0;
  appearance: none;

  &:not(:disabled):not([disabled]) {
    &:active {
      background-color: transparent;
    }

    &:not(:active) {
      &:focus {
        border-color: $button-focus-border-color;
        box-shadow: $button-focus-box-shadow;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

// states
@mixin states() {

  &:focus,
  &.focus {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 25%);
  }

  &:hover {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 12%);
  }

  &:active,
  &.active,
  .open >&.dropdown-toggle {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $hs-color-text;
      background-color: darken($hs-gray-50, 17%);
      /* stylelint-disable-next-line */
      border-color: darken($hs-gray-200, 25%);
    }
  }

  &:active,
  &.active,
  .open >&.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &:hover,
    &:focus,
    &.focus {
      background-color: $hs-gray-50;
      /* stylelint-disable-next-line */
      border-color: $hs-gray-200;
    }
  }

  .badge {
    color: $hs-gray-50;
    background-color: $hs-color-text;
  }
}

// Bootstrap Button Variant

@mixin hs-button-variant($hs-color-text, $hs-gray-50, $hs-gray-200) {
  color: $hs-color-text;
  background-color: $hs-gray-50;
  border-color: $hs-gray-200;

  &:focus,
  &.focus {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 25%);
  }

  &:hover {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $hs-color-text;
    background-color: darken($hs-gray-50, 10%);
    /* stylelint-disable-next-line */
    border-color: darken($hs-gray-200, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $hs-color-text;
      background-color: darken($hs-gray-50, 17%);
      /* stylelint-disable-next-line */
      border-color: darken($hs-gray-200, 25%);
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &:hover,
    &:focus,
    &.focus {
      background-color: $hs-gray-50;
      /* stylelint-disable-next-line */
      border-color: $hs-gray-200;
    }
  }

  .badge {
    color: $hs-gray-50;
    background-color: $hs-color-text;
  }
}

@mixin hs-button-color($hs-button-background-color: $hs-button-background-color, $hs-color-text: $hs-button-color) {
  color: $hs-color-text;
  background-color: $hs-button-background-color;
  border: $hs-button-border;

  &.hs-card-button--active {
    background-color: darken($hs-button-background-color, $hs-color-text-tint);
  }

  &:not(:disabled):not([disabled]) {
    &:not(:active) {
      &:hover {
        background-color: lighten($hs-button-background-color, $hs-color-text-tint);
      }
    }

    &:focus {
      border-color: $hs-button-focus-border-color;
      box-shadow: $hs-button-focus-box-shadow;
    }

    &:active {
      background-color: darken($hs-button-background-color, $hs-color-text-tint);
    }
  }
}

@mixin hs-card-button--ghost($hs-color-text: $hs-button-ghost-color, $hs-color-text-hover: $hs-button-ghost-hover-color) {
  color: $hs-color-text;
  background-color: transparent;
  border: $hs-button-ghost-border-width $hs-button-ghost-border-style $hs-color-text;

  &.hs-card-button--active {
    color: $hs-color-text-hover;
    background-color: darken($hs-color-text, $hs-color-text-tint);
    border-color: darken($hs-color-text, $hs-color-text-tint);
  }

  &:not(:disabled):not([disabled]) {
    &:not(:active) {
      &:hover {
        color: $hs-color-text-hover;
        background-color: $hs-color-text;
      }
    }

    &:focus {
      border-color: $hs-button-focus-border-color;
      box-shadow: $hs-button-focus-box-shadow;
    }

    &:active {
      color: $hs-color-text-hover;
      background-color: darken($hs-color-text, $hs-color-text-tint);
      border-color: darken($hs-color-text, $hs-color-text-tint);
    }
  }
}

@mixin button-shadowlined-variant($hs-button-background-color, $hs-lightnessMultiplier) {
  // @debug darken($hs-button-background-color, $hs-darken-tint);
  // @debug lighten($hs-button-background-color, $hs-lighten-tint);
  // @debug "set color:" set-color($hs-button-background-color, $hs-lightnessMultiplier);

  $darkenColor: darken($hs-button-background-color, $hs-darken-tint);
  $lightenColor: lighten($hs-button-background-color, $hs-lighten-tint);

  color: set-color($hs-button-background-color, $hs-lightnessMultiplier);
  background-color: $hs-button-background-color;
  border: darken($hs-button-background-color, $hs-lightnessMultiplier);
  box-shadow: v(hs-shadow-sm);

  &.card-button--active {
    background-color: darken($hs-button-background-color, $hs-darken-tint);
  }

  &:not(:disabled):not([disabled]) {
    &:not(:active) {
      &:hover {
        background-color: lighten($hs-button-background-color, $hs-lighten-tint);
      }
    }

    &:focus {
      border-color: darken($hs-button-background-color, ($hs-darken-tint + 2%));
      box-shadow: darken($hs-button-background-color, ($hs-darken-tint + 2%));
      box-shadow: v(hs-shadow-sm);
    }

    &:active {
      background-color: darken($hs-button-background-color, ($hs-darken-tint + 5%));
    }
  }
}

// Background Cover Mixin

@mixin background-cover {
  background-size: cover;
}

// Font Mixins

@mixin serif-font {
  font-family: $hs-font-serif;
}

@mixin alt-serif-font {
  font-family: $hs-font-alt-headings;
}

@mixin cursive-font {
  font-family: $hs-font-cursive-primary;
}

@mixin body-font {
  font-family: $hs-font-primary;
}

@mixin heading-font {
  font-family: $hs-font-secondary;
  text-transform: uppercase;
}

@mixin alt-heading-font {
  font-family: $alt-headings-font-family;
  text-transform: uppercase;
}

@mixin box-shadow-inset-blue {
  box-shadow: $hs-shadow-inset-blue;
}

@mixin transition-ease-dot3s {
  transition: all ease 0.3s;
}

@mixin border-radius-all-md {
  border-radius: $hs-gray-200-radius-md;
}

@mixin responsive-box {

  //@extend-elements
  %extend_responsive_box_defaults {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .responsive-box {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    .responsive-box-item {
      @extend %extend_responsive_box_defaults;
    }

    iframe {
      @extend %extend_responsive_box_defaults;
    }

    embed {
      @extend %extend_responsive_box_defaults;
    }

    object {
      @extend %extend_responsive_box_defaults;
    }

    video {
      @extend %extend_responsive_box_defaults;
    }
  }

  .responsive-box-16by9 {
    padding-bottom: 56.25%;
  }

  .responsive-box-4by3 {
    padding-bottom: 75%;
  }
}

// cards
@mixin banner-dimensions--sm {
  width: $hs-card-img-width--sm;
  height: $hs-card-img-height--sm;

  .hs-card_img-header_img {
    width: $hs-card-img-width--sm;
    height: $hs-card-img-height--sm;
  }
}

@mixin banner-dimensions--lg {
  width: $hs-card-img-width--lg;
  height: $hs-card-img-height--lg;

  .hs-card_img-header_img {
    width: $hs-card-img-width--lg;
    height: $hs-card-img-height--lg;
  }
}

@mixin banner-dimensions--fluid {
  width: $hs-card-img-width;
  height: $hs-card-img-height;
}

@mixin header-banner-sizes {
  &--sm {
    @include banner-dimensions--sm;
  }

  &--lg {
    @include banner-dimensions--lg;
  }

  &--fluid {
    @include banner-dimensions--fluid;
  }
}

@mixin card-header-dimensions {
  // .hs-card_header {
  @include banner-dimensions--fluid;
  // }
  @include header-banner-sizes;
}

@mixin card-size--sm {
  .hs-card {
    margin: inherit;

    &--sm {
      display: inline-block;
      min-width: 265px;
      max-width: 100%;
      height: auto;
      margin: 5px auto;
    }

    &--lg {
      display: inline-block;
      width: 400px;
      height: auto;
      margin: 5px auto;
    }

    &--fluid {
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 5px auto;
    }
  }
}

@mixin card-size--md {
  .hs-card {
    margin: 5px auto;

    &--sm {
      display: inline-block;
      width: 265px;
      height: auto;
      margin: 5px auto;

      .hs-card_title {
        font-size: 1rem;
      }
    }

    &--md {
      display: inline-block;
      width: 400px;
      max-width: 100%;
      height: auto;
      margin: 5px auto;
    }

    &--lg {
      display: inline-block;
      width: 600px;
      height: auto;
      margin: 5px auto;
    }

    &--fluid {
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 5px auto;
    }
  }
}

@mixin card-size--lg {
  .hs-card {
    margin: inherit;

    &--sm {
      display: inline-block;
      width: 265px;
      margin: 5px auto;
    }

    &--md {
      display: inline-block;
      width: 500px;
      height: auto;
      margin: 5px auto;
    }

    &--lg {
      display: inline-block;
      width: 80%;
      height: auto;
      margin: 5px auto;
    }

    &--fluid {
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 5px auto;
    }
  }
}


// affects outside the card-body component
@mixin hs-card-content {
  &.hs-card-size--sm {
    width: 100%;

    .hs-card_title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: $hs-card-title--sm;
    }

    .hs-card_subtitle {
      font-size: $hs-card-subtitle--sm;
    }
  }

  &.hs-card-size--lg {
    width: 100%;

    .hs-card_title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: $hs-card-title--lg;
    }

    .hs-card_subtitle {
      font-size: $hs-card-subtitle--lg;
    }
  }

  &.hs-card-size--fluid {
    width: $hs-card-width;

    .hs-card_title {
      margin-top: $hs-spacing--lg;
      margin-bottom: 0;
      font-size: $hs-card-title--lg;
    }

    .hs-card_subtitle {
      margin-top: $hs-spacing--lg;
      margin-bottom: $hs-spacing--lg;
      font-size: $hs-card-subtitle--fluid;
    }
  }
}

@mixin breakpoint($breakpoint, $logic: false) {
  @if ($logic) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }

  @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}

// --------------------------------
// CSS Triangle
// --------------------------------
@mixin triangle($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if ($direction==left) {
    border-right-color: $color;
  }

  @else if($direction==right) {
    border-left-color: $color;
  }

  @else if($direction==down) {
    border-top-color: $color;
  }

  @else {
    border-bottom-color: $color;
  }
}

// --------------------------------
// Typography
// --------------------------------

// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
    content: "";
  }
}

// edit text unit on a component level
@mixin textUnit($text-unit) {
  --text-unit: #{$text-unit};
  font-size: var(--text-unit);
}

// functions

// --------------------------------
// Colors
// --------------------------------

// define HSL color variable
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}

// ======* SHAPES ======* //
@mixin circle {
  border-radius: $hs-circle;
}

@mixin pill {
  border-radius: $hs-pill;
}

// *====== TIMELINE ======* //
@mixin timeline-panel-tag {
  position: absolute;
  top: -6px;
  padding: 2px 6px;
  font-size: 11px;
  color: $hs-white;
  text-transform: uppercase;
  background: $hs-color-text_green--dark;
}

// codyhouse.co
// used to create the auto-size columns of the grid system (.col class)
@mixin autoSizedColumn {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

// CSS Grid Layout fallback
@mixin gridFallback($gap: null) {
  display: flex;
  flex-wrap: wrap;

  @if ($gap==null) {
    $gap: var(--space-md);
  }

  margin: calc(#{$gap} * -1) 0 0 calc(#{$gap} * -1);

  >* {
    padding: #{$gap} 0 0 #{$gap};
    background-clip: content-box;
  }

  @supports (grid-area: auto) {
    margin: 0;

    >* {
      max-width: none !important;
      padding: 0;
    }
  }
}

@mixin column($width) {
  @if unit($width) !='%' {
    $width: $width * 100%;
  }

  flex-basis: #{$width};
  max-width: #{$width};
}



// --------------------------------
// Accessibility
// --------------------------------

// hide - content made available only to screen readers
@mixin srHide {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

// show
@mixin srShow {
  position: static;
  clip: auto;
  clip-path: none;
}

// --------------------------------
// Colors
// --------------------------------

// define HSL color variable
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}

// --------------------------------
// Typography
// --------------------------------

// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  &::before {
    content: '';
  }
}


@mixin textUnit($text-unit)  {
  --text-unit: #{$text-unit};
  font-size: var(--text-unit);
}

// --------------------------------
// Spacing
// --------------------------------

// edit space unit on a component level
@mixin spaceUnit($space-unit) {
  --space-unit: #{$space-unit};
}

// --------------------------------
// Reset
// --------------------------------

// reset user agent style
@mixin reset {
  padding: 0;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  appearance: none;
}

@mixin breakpoint($breakpoint, $logic: false) {
  @if ( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}

// Make color bariables
// @mixin color-modifiers($prefix, $separator) {
//   @each $name, $color in $colors {
//     @each $shade in $shades {
//       #{$prefix}#{$separator}#{$name}: $color;
//       @each $percent in $shade-percentages {
//         #{$prefix}#{$name}#{$separator}#{$shade}: tint-color($color, $percent);
//       }
//     }
//   }
// }
@mixin frosted-glass-background {
  font-size: .875rem !important;
  color: rgba(49, 49, 49, 0.5);
  font-weight: 700;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
}
@mixin blurredGlassBg {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
}
@mixin blurredGlassBgLeft {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  // @include border-top-radius($border-radius-lg);
}

@mixin blurredGlassBgRight {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  // @include border-top-radius($border-radius-lg);
}
