$rowColumnWidth: 100% !default;
$headerHeight: 100px !default;
$footerHeight: 200px !default;

:root {
  --headerHeight: #{$headerHeight};
  --footerHeight: #{$footerHeight};
  --rowColumnWidth: #{$rowColumnWidth};
}

.singleColumnLayoutContainer100 {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.singleColumnLayoutHeader {
  display: flex;
  height: 90px;
  color: var(--primary);
  // height: 66px;
  width: 100%;
  // background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.6) 69%, black 100%);
  transition: all .3s ease-in-out;
}
.absoluteTop {
  position: absolute;
  top: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
}
.fixedTop {
  position: Fixed;
  top: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
}


.singleColumnLayoutContent {
  position: relative;
  padding: 0;
  min-height: 100vh;
}

.singleColumnLayoutFooter {
  background:rgb(5, 8, 10);
  padding: 0;
  margin: 0;
}


.content {
  font-size: .875rem;
}

.container-sm {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: .35s;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: .35s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

// .main {
//   display: flex;

// }

/* sidebar
.container {
display: grid;
grid-template-columns: 300px 1fr;
grid-template-rows: 70px 1fr 200px;
gap: 0px 0px;
grid-template-areas:
  "sidebar header"
  "sidebar main"
  "sidebar footer";
}
.sidebar { grid-area: sidebar; }
.header { grid-area: header; }
.main { grid-area: main; }
.footer { grid-area: footer; }

<div class="container">
<div class="sidebar"></div>
<div class="header"></div>
<div class="main"></div>
<div class="footer"></div>
</div>
*/
