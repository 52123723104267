.frosty-glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.blurredGlassBg {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
}
.blurredGlassBgLeft {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  // @include border-top-radius($border-radius-lg);
}

.blurredGlassBgRight {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0;
  // @include border-top-radius($border-radius-lg);
}
