@import '../../scss/vars';

.hidden {
  opacity: 0 !important;
}

.visible {
  opacity: 1 !important;
}

.header-container > .container-fluid #header.home-greeting {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  color: #fff;
  text-align: center;
  // background: transparent;
  // background-color: linear-gradient(0deg, rgba(1, 1, 1, 0.981) 0%, #111314f8 100%);
  background-image: url('/assets/img/bg/workspace.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top center;
  transition: all .235s ease-in-out;



  // &::before {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   z-index: 1;
  //   width: 100%;
  //   min-height: 100vh;
  //   content: '';
  //   background: rgba(0, 0, 0, 0.411);
  // }
}

.greeting-container.zoomer {
  background-size: 110%;
  background-position: top -100px;
  transition: all .235s ease-in-out;
}

.greeting-container {
  width: 100%;
  background: rgba(0,0,0, .5);
}

.hs-intro-lead-in {
  @include heading-font;
  text-align: center;
  transition: font-size 0.3s ease-in-out;
}

.hs-intro-subheading {
  @include heading-font;
  padding-left: 0;
  font-weight: 700;
  text-align: center;
  transition: font-size 0.3s ease-in-out;
}

.hs-intro-heading {
  @include heading-font;
  text-align: center;
  transition: font-size 0.3s ease-in-out;
}

.greeting-button {
  margin-top: 6rem;
  background: var(--hs-black_75);
  border: 2px solid  var(--hs-color_dark-yellow) !important;
  &:visited {
    color: var(--hs-white);
    background: var(--hs-black_75);
  }
  &:hover {
    color: var(--hs-black_75);
    background-color: rgba(254, 211, 54, 0.486);
  }
  &:focus {
    outline: 2px solid var(--hs-color_dark-yellow) !important;
    border-color: var(--hs-color_dark-yellow);
    background-color: rgba(254, 211, 54, 0.486);
  }
}




  .hs-intro-text>.greetingLine>.fadein-bg-black_50 {
    animation: fadein-bgBlack_50;
    animation-duration: .8s;
    animation-timing-function: ease-in;
    animation-delay: 3s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }


@media (min-width: 200px) and (max-width: 767px) {
  #header.home-greeting {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    min-height: 100vh;
    color: #fff;
    text-align: center;
    // background: transparent;
    // background-color: linear-gradient(0deg, rgba(1, 1, 1, 0.981) 0%, #111314f8 100%);
    background-image: url('/assets/img/bg/workspace.jpg');
    background-size: 200%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    transition: all .235s ease-in-out;

    .greeting-container {
      width: 100%;
      background: rgba(0,0,0, .5);
      padding: .5rem
    }

    .hs-intro-lead-in {
      @include heading-font;
      text-align: center;
      transition: font-size 0.3s ease-in-out;
    }

    .hs-intro-text {

      transform: translateY(-25%);
      .hs-intro-subheading-01 {
        @include heading-font;
        padding-left: 0;
        font-weight: 700;
        text-align: center;
        transition: font-size 0.3s ease-in-out;
        .sub-heading-01 {
          font-size: 1.5rem;
        }

      }
      .hs-intro-subheading-03 {
        // @include heading-font;
        padding-left: 0;
        font-weight: 700;
        text-align: center;
        transition: font-size 0.3s ease-in-out;
        font-size: 1.5rem;
      }

      .greeting-button,
      .js-page-trigger.btn.btn-xl.bg-black-semitrans {
        background: var(--hs-black_75);
        border: 2px solid  var(--hs-color_dark-yellow) !important;
        padding: .5rem .8rem;
        margin-top: 20px;
      }

      .hs-intro-heading {
        @include heading-font;
        font-size: 2rem;
        text-align: center;
        transition: font-size 0.3s ease-in-out;
      }
    }


  }
}


@media (min-width: 768px) {

  #header.home-greeting {
    background-image: url('/assets/img/bg/workspace.jpg');
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    transition: all .03s ease-in-out;

  }
    .greeting-container {
      width: 100%;
      color: var(--hs-white);
      // background: rgba(0,0,0, .5);
      padding: .5rem
    }

    .hs-intro-lead-in {
      @include heading-font;
      text-align: center;
      transition: font-size 0.3s ease-in-out;
    }

    .hs-intro-text {
      text-align: center;
      transform: translateY(-25%);
    }
    .hs-intro-heading {
      font-size: 6rem !important;
      transition: font-size 0.3s ease-in-out;
    }
      .hs-intro-subheading-01 {
        @include heading-font;
          padding-left: 0;
          font-weight: 700;
          text-align: center;
          transition: font-size 0.3s ease-in-out;
        .sub-heading-01 {
          font-size: 4rem;
        }

      }
      .hs-intro-subheading-03 {
        // @include heading-font;
        padding-left: 0;
        font-weight: 700;
        text-align: center;
        transition: font-size 0.3s ease-in-out;
        font-size: 4rem;
      }

      .hs-intro-heading {
        @include heading-font;
        font-size: 2rem;
        text-align: center;
        transition: font-size 0.3s ease-in-out;
      }

      .greeting-button {
        background: var(--hs-black_75);
        border: 2px solid  var(--hs-color_dark-yellow) !important;
        padding: .5rem .8rem;
        margin: 20px auto;
      }




}

@media (min-width: 912px) {
  .greeting-container {
    width: 100%;
    background: rgba(0,0,0, .5);
    padding: .5rem;
  }

  .hs-intro-heading {
    font-size: 6rem !important;
  }

  .hs-intro-lead-in {
    @include heading-font;
    text-align: center;
    transition: font-size 0.3s ease-in-out;
  }

  .hs-intro-text {
    transform: unset;
    .hs-intro-subheading-01 {
      @include heading-font;
      padding-left: 0;
      font-size: 4rem;

      transition: font-size 0.3s ease-in-out;
      .sub-heading-01 {
        font-size: 4rem !important;
        font-weight: 700;
        text-align: center;
      }

    }
    .hs-intro-subheading-03 {
      // @include heading-font;
      padding-left: 0;
      font-weight: 700;
      text-align: center;
      transition: font-size 0.3s ease-in-out;
      font-size: 4rem !important;
    }
  }
    .greeting-button,
    .js-page-trigger.btn.btn-xl.bg-black-semitrans {
      background: var(--hs-black_75);
      border: 2px solid  var(--hs-color_dark-yellow) !important;
      padding: .5rem .8rem;
      margin-top: 20px;
    }

    .hs-intro-heading {
      @include heading-font;
      font-size: 2rem;
      text-align: center;
      transition: font-size 0.3s ease-in-out;
    }

}

@include media-breakpoint-up(lg) {

  // background: url("/static/media/woodenDesktop-unsplash.106e8600.jpg") center center / cover no-repeat scroll transparent;
  header.masthead,
  .home-greeting {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    color: #fff;
    text-align: center;
    // background-color: linear-gradient(0deg, rgba(1, 1, 1, 0.981) 0%, rgba(17, 19, 20, 0.973) 100%);
    background-image: url('/assets/img/bg/workspace.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    padding-top: 1rem;
    // &::before {
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    //   z-index: 0;
    //   width: 100%;
    //   min-height: 100vh;
    //   content: '';
    //   background: rgba(0, 0, 0, 0.233);
    // }


    .hs-intro-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      line-height: normal;
      text-align: center;
      transition: font-size 0.3s ease-in-out;

      .hs-intro-heading-wrap {
        display:  inline-block;
        margin-bottom: 4px;
      }
      .hs-intro-heading {
        display: inline-block;
        @include heading-font;
        font-size: 6rem !important;
        color: #fff;
        margin-bottom: 4px;
        font-weight: 700;
        line-height: normal;
        // animation-duration: 3s;
        transition: font-size 0.3s ease-in-out;
        text-shadow: $hs-text-shadow-light-spread;

      }


      .hs-intro-subheading-01 {
        margin-bottom: 4px;
        transition: font-size 0.3s ease-in-out;
        .sub-heading-01 {
          margin-bottom: 0;
          font-size: 4rem !important;
          // padding: 1rem;
          line-height: normal;
          @include heading-font;
          text-transform: uppercase;
          font-weight: 700;
          text-shadow: $hs-text-shadow-light-spread;
          transition: font-size 0.3s ease-in-out;

        }
      }


      .hs-intro-subheading-02 {
        margin-bottom: 4px;
        padding: 1rem;
        transition: font-size 0.3s ease-in-out;
        .sub-heading-02 {
          margin-bottom: 0;
          // padding: 1rem;
          font-size: 3.5rem;
          line-height: normal;
          @include heading-font;
          font-weight: 700;
          text-transform: uppercase;
          text-shadow: $hs-text-shadow-light-spread;
          transition: font-size 0.3s ease-in-out;
        }

      }
      .hs-intro-subheading-03 {
        margin-bottom: 4px;
       padding: 1rem;
        transition: font-size 0.3s ease-in-out;
        .sub-heading-03 {
          margin-bottom: 0;
          font-size: 4rem !important;
          // padding: 1rem;
          line-height: normal;
          @include heading-font;
          font-weight: 700;
          text-transform: uppercase;
          text-shadow: $hs-text-shadow-light-spread;
          transition: font-size 0.3s ease-in-out;
        }

      }


      .btn-xl.bg-black-semitrans {
        text-transform: uppercase;
        padding: 1.35rem 2rem;
        font-size: 1.5rem;
        font-weight: 700;
        border-radius: 3px;
      }
    }
  }
}


.hs-divider {
  display: flex;
  height: 42px;
}

.hs-divider-h4 {
  display: flex;
  height: 4px;
}


.timing-05s {
  animation-delay: 0.5s;
}

.timing-075s {
  animation-delay: 0.75s;
}

.timing-1s {
  animation-delay: 1s;
}

.timing-1-5s {
  animation-delay: 1.5s;
}

.timing-1-75s {
  animation-delay: 1.75s;
}


.timing-2-5s {
  animation-delay: 2.5s;
}

.timing-2-75s {
  animation-delay: 2.75s;
}


.timing-3-2s {
  animation-delay: 3.2s;
}

.timing-5-57s {
  animation-delay: 5.57s;
}

@keyframes slide-bl {
  0% {
    font-size: 12rem;
    transform: translateY(0) translateX(calc(0% - 2000px)) scale(9);
    animationPlayState: paused, paused, running;
    animation-timing-function: ease-in, ease-out;
    transition: all .24sl;
  }
  40% {
    transform: translateY(0) translateX(calc(100% + 2000px)) scale(9);
    animationPlayState: paused, paused, paused, running;
    animation-timing-function: ease-in, ease-out;
    transition: all .24sl;
  }
  60% {
    transform: translateY(0) translateX(0) scale(9);
    // animationPlayState: paused, paused, paused, paused, paused, running;
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: ease-in, linear;
    transition: all .24sl;
  }
  85% {
    transform: translateY(0) translateX(0) scale(5);
    // animationPlayState: paused, paused, paused, paused, paused, running;
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: ease-in, linear;
    transition: all .24sl;
  }
  100% {
    font-size: 8rem;
    transform: translateY(0) translateX(0) scale(1);
    transition: all .24sl;
    animation-fill-mode: forwards;
  }
}

@keyframes bgZoomer {
  0% {
    background-position: top center;
    background-size: 100%
  }
  50% {
    background-position: top -100px;
    background-size: 110%
  }
  100% {
    background-position: top center;
    background-size: 100%
  }
}


@keyframes text-focus-in {
  0% {
    filter: blur(0);
    opacity: 0;
  }

  50% {
    // filter: blur(12px);
    opacity: 0.95;
  }

  100% {
    filter: blur(0);
    opacity: 1;
  }
}






.fadein-bg-black_50 {
  animation: fadein-bgBlack_50;
  animation-duration: .8s;
  animation-timing-function: ease-in;
  animation-delay: 3s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes subHeading-scaleblur {
  0% {
    transform: scale(4);
    filter: blur(0);
  }

  50% {
    transform: scale(4);
    filter: blur(50px);
    // animationPlayState: paused, running;
  }

  100% {
    transform: scale(4);
    filter: blur(0);
  }
}

@keyframes fadein-bgBlack_50-withBlur {
  0% {
    box-shadow: inset 0 0 34px 38px rgb(0 0 0 / 0%);
    filter: blur(0);
  }

  50% {
    // filter: blur(12px);
  }

  100% {
    box-shadow: inset 0 0 34px 38px rgb(0 0 0 / 50%);
    filter: blur(0);
  }
}

@keyframes fadein-bgBlack_50 {
  0% {
    box-shadow: inset 0 0 34px 38px rgb(0 0 0 / 0%);
    filter: blur(0);
  }

  50% {
    // filter: blur(8rem)
  }

  100% {
    box-shadow: inset 0 0 34px 38px rgb(0 0 0 / 50%);
    filter: blur(0);
  }
}
