@import '../../scss/variables/functions';
@import '../../scss/variables/mixins';
@import '../../scss/variables/colors';

.logo-container {
  width: 190px;
  margin-left: 1.5rem;
}

.theme-mode-widget-container {
  width: 190px;
  margin-right: 1.5rem;
  display: none;
}

.site-logo {
  display: inline-block;
  height: 50px;
  font-size: 2rem;
  font-weight: 400;
  line-height: 0.7;
  color: $hs-yellow;
  padding-left: 36px;
  @include cursive-font;
  text-decoration: none;

  &:hover {
    color: $hs-yellow-hover;
    text-shadow: 0 -1px 4px transparent, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #f00;
    transition: all 0.2s ease-out;
  }

  .hs-brand-symbol,
  .hs-site-logo-symbol {
    padding-bottom: 10px;
    font-size: 3.4rem;
    @include cursive-font;
    color: #ffc107;

    &:hover {
      color: darken(#ffc107, 3%);
      text-shadow: 0 -1px 4px transparent, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #f00;
      transition: all 0.2s ease-out;
    }

    &:active,

    &:focus {
      color: #ffc107;
    }
  }

  a:link {
    color: #ffc107;
  }

  &:visited,
  &:hover {
    color: darken(#ffc107, 3%);
  }

}

.hs-brand {
  padding-bottom: 10px;
  font-size: 2.5rem;
  @include cursive-font;
  color: $primary;

  &:active,
  &:visited,
  &:focus {
    color: #ffc107;
  }
}

.hs-brand-symbol:hover+.hs-brand:hover {
  color: #ffe347;
  text-shadow:
    0px -2px 4px #fff,
    0px -2px 10px #FF3,
    0px -10px 20px #F90,
    0px -20px 40px #C33;
}


.site-logo-link {
  color: #ffc71e;
}

.navbar {
  .container-fluid {
    .navbar-toggler {
      color: #fff;
      background: rgba(0, 0, 0, .5);
      border: 1px solid rgba(254, 211, 54, 0.826);
      display: none;
      // box-shadow: 0 0 2px 2px rgba(254, 209, 54, .5);

      &>navbar-toggler-icon {
        border: 2px solid #fff;
      }

      &:focus {
        box-shadow: 0 0 2px 2px rgba(254, 209, 54, .5);
      }
    }

  }

}

.nav-link {
  font-size: 1.3rem;
}
.userMessage {
  color:rgb(255, 247, 219)
}

// .widget-container {
//   display: none;
//   margin-top: -46px;
//   list-style-type: none;
//   white-space: nowrap;
// }

// ======* Navbar *====== //
.bg-black2transparent-nav {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 1) 59%, rgba(0, 0, 0, 1) 100%);
}

.background-switcher.fadeOut {
  position: absolute;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 1) 59%, rgba(0, 0, 0, 1) 100%);

  // overflow:hidden;
  &::before {
    position:absolute;
    top: -80px;
    left: 0;
    z-index: -1;
    height: 80px;
    width: 100%;
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 1) 59%, rgba(0, 0, 0, 1) 100%);
    box-shadow: 0 3px 8px 3px rgba(0, 0, 0, 0.5);
    transition: all .35s ease-in-out;
  }
}

.background-switcher.fadeIn {
  position: absolute;
  top: 0;
  // overflow:hidden;
  &::before {
    position:absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 80px;
    content: '';
    background: linear-gradient(0deg, #151515 0%,  #373737 100%);
    box-shadow: 0 3px 8px 3px rgba(0, 0, 0, 0.5);
    transition: all .35s ease-in-out;
  }
}

.navbar-expand-md {
  .navbar-nav {
    display: inline-flex;
    margin: auto;

    .nav-link {

      &.nav-link,
      .nav-link:visited {
        color: #FFF;
        margin: 0 1.2rem;

        &:hover,
        &:focus {
          color: #ffe347;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .header-container {
    .container-fluid {
      .navbar {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 20%, rgba(0, 0, 0, 0.2) 52%, rgba(0,0,0, 75%) 100%);
      }
    }
  }
  .theme-mode-widget-container {
    display: none;
    .theme-mode-widget {
      font-size: 1rem;
    }
  }

  .collapser {
    position: absolute;
    top: 60px;
    width: 100%;
    padding-right: 1.438rem;
  }

  .navbar {
    .container-fluid {
      .navbar-toggler {
        color: #fed136;
      }

      .navbar-nav {
        background: transparent;
        width: 100%;
      }
    }
  }
}



  @media (min-width: 768px) {
    .theme-mode-widget-container {
      display: inline-block;
    }

    .logo-container {
      padding-left: 0;
    }

    .navbar {
      .container-fluid {
        .navbar-toggler {
          visibility: hidden;
        }
      }
    }

    .collapser {
      display: flex;
      position: unset;
      top: unset;
    }

    .navbar-nav {
      background: transparent;

    }
  }
  @media (min-width: 412px) {
    .site-logo {
      font-size: 2rem;
    }
    .navbar {
      margin-top: .5rem;
    }
  }

  @media (min-width: 200px) {
    .main-nav {
      background: var(--hs-black_75);
      width: 100%;
      text-align: center;
    }
    .container-fluid {
      padding: 0;
    }
    #navbarToggler.navbar-toggler {
      margin: 0 auto;
      background: var(--hs-black_75);
    }
  }

  .z-index-dropdown {
    z-index: 1000;
  }
