.absoluteTop {
  position: absolute  !important;
  top: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
}
.fixedTop {
  position: fixed !important;
  top: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
  transform: translateY(0) !important;
}
.container-fluid {
  .header-container {
    position:relative;
  }
}
