.portfolioGalleryHeading {
  color: #fff;
}

[class$="MuiMasonry-root"] {
  .text-center {
    position: relative;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
      position: absolute;
      bottom: 0;
      z-index: 100;
      width: 100%;
      color: #fff;
      background: rgba(0,0,0, .75);
      padding: .5rem;
      border-radius: 0;
    }
  }
}
